import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);

const Landing= Loadable(lazy(()=>import("views/forms/landing/index")))
const Login =Loadable(lazy(()=>import("views/pages/authentication/Login/Login")))
const Register =Loadable(lazy(()=>import("views/pages/authentication/Register/Register")))
const VerifyOtpForRegister=Loadable(lazy(()=>import("views/pages/authentication/Register/VerifyOtp/VerifyOtp")))
const ResetPassword=Loadable(lazy(()=>import("views/pages/authentication/ResetPassword/ResetPassword")))
const ResetPasswordForm =Loadable(lazy(()=>import("views/pages/authentication/ResetPassword2/ResetPassword2")))
const VerifyOtpForResetPassword=Loadable(lazy(()=>import("views/pages/authentication/ResetPassword2/VerifyOtp/VerifyOtp")))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path:"/",
      element:<Landing/>
    },
    {
      path:"/login",
      element:<Login/>
    },
    {
      path:"/register",
      element:<Register/>
    },
    {
      path:"/reset-password",
      element:<ResetPassword/>
    },
    {
      path:"/reset-password-form",
      element:<ResetPasswordForm/>
    },
    {
      path:"/verify-email-for-signup",
      element:<VerifyOtpForRegister/>
    },
    {
      path:"/verify-email-for-reset-password",
      element:<VerifyOtpForResetPassword/>
    }
    // {
    //   path: "/login",
    //   element: <AuthLogin />,
    // },
  ],
};

export default LoginRoutes;
