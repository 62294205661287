// import other from "./other";

// // ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//   items: [other],
// };

// export default menuItems;

import other from "./other";
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    other,
  ],
};

export default menuItems;
