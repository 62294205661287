import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

//Admin Profile and Password
const UserSetting = Loadable(lazy(() => import("views/forms/userSetting/index")));
const ChangePassword = Loadable(lazy(() => import("views/application/account-profile/changeAdminPassword.js")));
const UpdateProfile = Loadable(lazy(() => import("views/application/account-profile/updateProfile.js")));
const Records = Loadable(lazy(() => import("views/forms/Records/index")));
const UserForQr= Loadable(lazy(() => import("views/forms/Records/UserList/UserList")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/company/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/company/update-profile",
      element: <UpdateProfile />,
    },
    {
      path: "/myaccount",
      element: <Records />,
    },
    {
     path:"/myaccount/:id",
     element:<UserForQr/>
    },
    {
      path: "/mysettings",
      element: <UserSetting />,
    },
  ],
};

export default MainRoutes;
