import { GET_RECORDS,GET_USER_FOR_QR } from "./actions";

const initialState = {
    records: {},
    users:{},
    loading: true
};

export default function recordReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_RECORDS:
            return {
                ...state,
                loading: false,
                records: payload
            };
            case GET_USER_FOR_QR:
                return {
                    ...state,
                    loading: false,
                    users: payload
                };
        default:
            return state;
    }
}