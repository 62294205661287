import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import Loadable from "ui-component/Loadable";

// import MinimalLayout from 'layout/MinimalLayout';
const FirstLoadLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const Landing= Loadable(lazy(()=>import("views/forms/landing/index")))
const Privacy=Loadable(lazy(()=>import("views/forms/PrivacyPolicy/index")))
const Terms =Loadable(lazy(()=>import("views/forms/TermsCondition/index")))
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
     { path: "/", element: <Landing /> },
     {path:"/privacy-policy",element:<Privacy/>},
     {path:"/terms-condition",element:<Terms/>},
    LoginRoutes,
    MainRoutes,
  ]);
}
