// auth provider
import { useSelector } from "react-redux";
// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, loading } = auth;

  return auth;
};

export default useAuth;

// import { useContext } from 'react';

// // auth provider
// import AuthContext from 'contexts/FirebaseContext';
// // import AuthContext from 'contexts/Auth0Context';
// // import AuthContext from 'contexts/JWTContext';
// // import AuthContext from 'contexts/AWSCognitoContext';

// // ==============================|| AUTH HOOKS ||============================== //

// const useAuth = () => {
//     const context = useContext(AuthContext);

//     if (!context) throw new Error('context must be use inside provider');

//     return context;
// };

// export default useAuth;
